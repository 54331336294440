import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import Image from 'gatsby-plugin-sanity-image'
import PortableText from '../components/portable-text';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';

export default function NewsItem({news, showImage, onOpen}) {
  const [open, setOpen] = useState(false);

  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        gap: var(--gutter);
        row-gap: 0;
        padding-top: 1.5rem;
        border-top: 1px solid var(--black);
        cursor: pointer;

        &:hover {
          .news-item--year {
            border-bottom-color: var(--black);
            transition: border-bottom-color 0s;
          }
        }
      `}
      onClick={() => {
        setOpen(!open);
        if (onOpen) onOpen();
      }}
      onKeyPress={(e) => {
        e.persist();

        if (e.key === 'Enter') {
          setOpen(!open);
          if (onOpen) onOpen();
        }
      }}
    >
      <div
        className="type--mono"
        css={css`
          grid-column: span 2;
          position: relative;
          line-height: 1.8;

          @media (max-width: 900px) {
            grid-column: span 12;
          }
        `}
      >
        <div
          className="news-item--year"
          css={css`
            display: inline-block;
            line-height: 1.2;
            border-bottom: 1px solid transparent;
            transition: border-bottom-color 0.6s var(--curve);
          `}
        >
          {new Intl.DateTimeFormat('en-AU', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit'
          }).format(new Date(news.date)).replace(/\//g, '.')}
        </div>
      </div>
      <div
        css={css`
          grid-column: span 7;

          @media (max-width: 900px) {
            grid-column: span 10;
          }
        `}
      >
        <div
          css={css`
            margin-bottom: 2rem;
          `}
        >
          {news.title}
        </div>
        <PortableText
          css={css`
            > * {
              display: none;
            }

            > p:first-of-type {
              display: block;
              margin-bottom: 1em;
            }
          `}
          blocks={news._rawText}
        />
      </div>
      <div
        css={css`
          position: relative;
          top: -2px;
          grid-column: span 1;
          line-height: 0;
          text-align: right;
          padding-right: 5px;

          @media (max-width: 900px) {
            grid-column: span 2;
          }
        `}
        role="button"
        aria-expanded={(open) ? true : false}
        tabIndex="0"
      >
        <span
          css={css`
            position: absolute;
            top: 9px;
            right: 5px;
            width: 11px;
            height: 1px;
            background-color: var(--black);
          `}
        ></span>
        <span
          css={css`
            position: absolute;
            top: 4px;
            right: 10px;
            width: 1px;
            height: 11px;
            background-color: var(--black);
            transform: rotate(${(!open) ? 0 : '90deg'});
            transition: transform 0.6s var(--curve);
          `}
        ></span>
      </div>
      <SlideDown
        css={css`
          grid-column-start: 3;
          grid-column-end: span 7;
          transition: height 0.6s var(--curve);

          @media (max-width: 900px) {
            grid-column-start: 1;
            grid-column-end: span 10;
          }
        `}
        closed={!open}
      >
        <div
          css={css`
            padding-bottom: 2rem;
          `}
        >
          <PortableText
            css={css`
              > p:first-of-type {
                display: none;
              }
            `}
            blocks={news._rawText}
          />
          {(showImage && news.image) && <img
            css={css`
              width: 100%;
              margin-top: 2rem;
            `}
            src={news.image.asset.gatsbyImageData.images.fallback.src}
          />}
        </div>
      </SlideDown>
    </div>
  )
}
