import React, { useState } from 'react';
import { css } from '@emotion/react';

export default function Button({ external, submit, url, text, arrow, className, onClick }) {
  const [hide, setHide] = useState(false);

  return (
    <a
      target={(external) ? '_blank' : '_self' }
      rel="noreferrer"
      href={url}
      className={`type--small-uppercase ${className}`}
      css={css`
        display: inline-flex;
        justify-content: space-between;
        color: var(--black);
        text-decoration: none;
        padding: 1.8rem 1.5rem 1.5rem;
        border: 1px solid var(--black);
        opacity: ${(hide) ? 0 : 1};
        transition: color 0.6s var(--curve), background-color 0.6s var(--curve), opacity 0.6s var(--curve);

        &:hover {
          color: var(--white);
          background-color: var(--black);

          svg path {
            fill: var(--white); 
          }
        }

        svg path {
          fill: var(--black); 
          transition: fill 0.6s var(--curve);
        }
      `}
      onClick={() => {
        if (submit) {
          document.getElementById(submit).submit();
        } else if (onClick) {
          onClick();
          setHide(true);
        }
      }}
    >
      <div
        css={css`
          margin-right: 4rem;
        `}
      >
        {text}
      </div>
      <div>
        {arrow} 
      </div>
    </a>
  )
}
