import React from 'react';
import { TransitionGroup, Transition } from 'react-transition-group';

export default function FadeTransition({ children, location }) {
  const duration = 600;

  const transitionStyles = {
    entering: {
      position: 'absolute',
      opacity: 0,
    },
    entered: {
      transition: `opacity ${duration}ms var(--curve)`,
      opacity: 1,
    },
    exiting: {
      transition: `opacity ${duration}ms var(--curve)`,
      opacity: 0,
    },
  };

  return (
    <TransitionGroup
      component={null}
    >
      <Transition
        key={location.pathname}
        timeout={{
          enter: duration,
          exit: duration,
        }}
      >
        {state => (
          <div
            style={{
              ...transitionStyles[state],
            }}
          >
            {children}
          </div>
        )}
      </Transition>
    </TransitionGroup>
  )
}
