import React, { useState, useLayoutEffect, useContext } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { css } from '@emotion/react';
import Button from '../components/button';
import PortableText from '../components/portable-text';
import ArrowRight from '../images/svg/arrow-right.svg'
import And from '../images/svg/and.svg';

export default function Footer() {
  const data = useStaticQuery(graphql`
    query {
      footer: sanityFooter(_id: {eq: "footer"}) {
        subscribeText
        addresses {
          _rawContent
        }
        instagramLink
        linkedInLink
      }
    }
  `);

  return (
    <footer
      css={css`
        display: flex;
        flex-wrap: wrap;
        position: relative;
        background-color: var(--white);
        padding: 0 var(--margin);
        border-top: 1px solid var(--black);

        @media (max-width: 900px) {
          padding: 0
        }
      `}
    >
      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(6, 1fr);
          gap: var(--gutter);
          flex: 0 0 50%;
          padding: 8rem 0 18rem;
          padding-right: calc(var(--gutter) / 2);
          border-right: 1px solid var(--black);

          @media (max-width: 900px) {
            display: block;
            flex: 0 0 100%;
            padding: 5rem var(--margin);
            border-right: 0;
            border-bottom: 1px solid var(--black);
          }
        `}
      >
        <div
          css={css`
            grid-column: span 4;
          `}
        >
          <div
            className="type--large"
            css={css`
              margin-bottom: 1.5rem;
            `}
          >
            Stay in touch
          </div>
          <div
            css={css`
              margin-bottom: 6rem;
            `}
          >
            {data.footer?.subscribeText}
          </div>
          <form
            id="subscribe-form"
            action="https://timeplace.us7.list-manage.com/subscribe/post?u=2377c883a35537cbc032add7e&id=4125a8ba74"
            method="post"
            target="_blank"
          >
            <input
              className="type--medium"
              css={css`
                width: 100%;
                font-family: 'Maax Medium', sans-serif;
                background-color: transparent;
                padding: 1.4rem 0;
                border: 0;
                border-bottom: 1px solid var(--black);
                margin-bottom: 4rem;
              `}
              type="email"
              name="EMAIL"
              placeholder="Enter email address"
            />
            <Button
              submit="subscribe-form"
              text="Sign Up"
              arrow={<ArrowRight />}
            />
          </form>
        </div>
      </div>
      <div
        className="type--mono"
        css={css`
          display: grid;
          grid-template-columns: repeat(6, 1fr);
          gap: var(--gutter);
          flex: 0 0 50%;
          padding: 9.5rem 0 8rem;
          padding-left: calc(var(--gutter) / 2);

          @media (max-width: 900px) {
            display: block;
            flex: 0 0 100%;
            padding: 5rem var(--margin);
            border-right: 0;
          }

          a {
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        `}
      >

        {data.footer?.addresses.length > 0 && data.footer?.addresses
          .map((address, i) => (
            <div
              key={i}
              css={css`
                grid-column-start: ${(i % 2 === 0) ? 2 : 5 };
                grid-column-end: span 3;
                margin-bottom: 8rem;

                &:last-of-type {
                  margin-bottom: 0;
                }
              `}
            >
              <PortableText blocks={address._rawContent} />
            </div>
          ))
        }
      </div>
      <div
        className="type--extra-small"
        css={css`
          position: absolute;
          bottom: var(--margin);
          left: var(--margin);
          font-family: 'Maax Medium', sans-serif;

          @media (max-width: 900px) {
            position: static;
            padding: 5rem var(--margin) var(--margin);
            border-right: 0;
          }

          
          ul {
            padding: 0;
            margin: 0;

            li {
              display: inline-block;
              list-style: none;
              margin-right: 3rem;

              @media (max-width: 900px) {
                display: block;
                margin-right: 0;
                margin-bottom: 2rem;
              }

              &:last-of-type {
                margin-right: 0;
              }
            }
          }

          a {
            color: var(--black);
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        `}
      >
        <ul>
          <li>
            <a target="_blank" href={data.footer?.instagramLink}>Instagram</a>
          </li>
          <li>
            <a target="_blank" href={data.footer?.linkedInLink}>LinkedIn</a>
          </li>
        </ul>
      </div>
      <nav
        className="type--extra-small"
        css={css`
          position: absolute;
          bottom: var(--margin);
          right: var(--margin);
          font-family: 'Maax Medium', sans-serif;

          @media (max-width: 900px) {
            position: static;
            padding: 5rem var(--margin) var(--margin);
            border-right: 0;
          }

          
          ul {
            padding: 0;
            margin: 0;

            li {
              display: inline-block;
              list-style: none;
              margin-right: 3rem;

              @media (max-width: 900px) {
                display: block;
                margin-right: 0;
                margin-bottom: 2rem;
              }

              &:last-of-type {
                margin-right: 0;
              }
            }
          }

          a {
            color: var(--black);
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        `}
      >
        <ul>
          <li>
            <Link to="/privacy-policy/">Privacy Policy</Link>
          </li>
          <li>
            Copyright {new Date().getFullYear()}
          </li>
        </ul>
      </nav>
      <div
        css={css`
          display: none;
          position: absolute;
          bottom: var(--margin);
          right: var(--margin);
          padding-bottom: 1.4rem;

          @media (max-width: 900px) {
            display: block;
          }
        `}
      >
        <And />
      </div>
    </footer>
  )
}
