import React, { useState } from 'react';
import { css } from '@emotion/react';
import Vimeo from '@u-wave/react-vimeo';
import { MdPause, MdPlayArrow } from 'react-icons/md';

export default function SmallVideo({ video }) {
  const [player, setPlayer] = useState(null);
  const [paused, setPaused] = useState(true);

  return (
    <div>
      <div
        css={css`
          border: 1px solid var(--black);
        `}
      >
        {video && <Vimeo
          video={video}
          controls={false}
          onReady={(player) => setPlayer(player)}
          responsive
          loop
        />}
      </div>
      <div
        className="type--small-uppercase"
        css={css`
          display: flex;
          justify-content: space-between;
          padding: 1.8rem 1.5rem 1.5rem;
          border: 1px solid var(--black);
          border-top: 0;
          cursor: pointer;
        `}
        onClick={() => {
          player?.getPaused().then((paused) => {
            if (paused) {
              player.play();
              setPaused(false);
            } else {
              player.pause();
              setPaused(true);
            }
          });
        }}
      >
        <div>
          {(paused) ? 'Play' : 'Pause'}
        </div>
        <div
          css={css`
            position: relative;
            top: -0.3rem;
            font-size: 2rem;
            height: 0;
            line-height: 0;
          `}
        >
          {(paused) ? <MdPlayArrow /> : <MdPause />}
        </div>
      </div>
    </div>
  )
}
