import React, { createContext, useState } from 'react';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [logoColorDark, setLogoColorDark] = useState(true);
  const [menuColorDark, setMenuColorDark] = useState(true);
  const [backgroundColor, setBackgroundColor] = useState(true);
  const [showMenu, setShowMenu] = useState(null);
  const [showNews, setShowNews] = useState(null);
  const [postIntro, setPostIntro] = useState(null);

  return (
    <ThemeContext.Provider
      value={{
        logoColorDark,
        menuColorDark,
        backgroundColor,
        showMenu,
        showNews,
        postIntro,
        setLogoColorDark,
        setMenuColorDark,
        setBackgroundColor,
        setShowMenu,
        setShowNews,
        setPostIntro,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}
