import React from 'react';
import { css } from '@emotion/react';
import Image from 'gatsby-plugin-sanity-image';

export default function ImageButton({ image, imageUrl, external, url, text, arrow, className, onClick, onKeyPress }) {
  return (
    <a
      target={(external) ? '_blank' : '_self' }
      rel="noreferrer"
      href={url}
      className={className}
      css={css`
        display: block;
        text-decoration: none;
        line-height: 0;
        border: 1px solid var(--black);

        &:hover {
          > div {
            color: var(--white);
            background-color: var(--black);

            svg path {
              fill: var(--white); 
            }
          }
        }
      `}
      onClick={onClick}
      onKeyPress={onKeyPress}
    >
      {image && <Image
        {...image}
        width={660}
        style={{
          width: '100%',
        }}
      />}
      {imageUrl && <img
        src={imageUrl}
      />}
      <div
        className="type--small-uppercase"
        css={css`
          display: inline-flex;
          justify-content: space-between;
          width: 100%;
          color: var(--black);
          text-decoration: none;
          padding: 1.8rem 1.5rem 1.5rem;
          border-top: 1px solid var(--black);
          transition: color 0.6s var(--curve), background-color 0.6s var(--curve);

          svg path {
            fill: var(--black); 
            transition: fill 0.6s var(--curve);
          }
        `}
      >
        <div
          css={css`
            margin-right: 4rem;
          `}
        >
          {text}
        </div>
        <div>
          {arrow} 
        </div>
      </div>
    </a>
  )
}
