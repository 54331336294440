import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { getGatsbyImageData } from 'gatsby-source-sanity'

export default function SEO({title, description, image, imageID}) {
  const { siteSettings } = useStaticQuery(query)
  title = (title) ? `${title} — ${siteSettings.siteTitle}` : `${siteSettings.siteTitle}`;
  description = (description) ? description : siteSettings.siteDescription;
  image = (image) ? image : siteSettings.siteSocialImage.asset.gatsbyImageData.images.fallback.src;

  if (imageID) {
    const data = getGatsbyImageData(imageID, { width: 1980 }, { projectId: process.env.SANITY_PROJECT_ID, dataset: process.env.SANITY_PROJECT_DATASET });
    if (data.images.fallback) {
      image = getGatsbyImageData(imageID, { width: 1980 }, { projectId: process.env.SANITY_PROJECT_ID, dataset: process.env.SANITY_PROJECT_DATASET })?.images.fallback.src;
    } else if (data.images.sources) {
      image = getGatsbyImageData(imageID, { width: 1980 }, { projectId: process.env.SANITY_PROJECT_ID, dataset: process.env.SANITY_PROJECT_DATASET })?.images.sources[0].srcSet.split(' ').reverse()[1].split('\n')[1];

    }
  }  return (
    <Helmet title={title} defer={false}>
      <html lang="en" />

      <meta name="description" content={description} />
      <meta name="image" content={image} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
    </Helmet>
  )
}

const query = graphql`
  query SEOQuery {
    siteSettings: sanitySiteSettings(_id: { regex: "/(drafts\\\\.)?siteSettings/" }) {
      siteDescription
      siteTitle
      siteSocialImage {
        asset {
          gatsbyImageData(width: 1980)
        }
      }
    }
  }
`
