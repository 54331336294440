import React, { useContext } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Link } from 'gatsby';
import { Global, css } from '@emotion/react';
import { ThemeProvider, ThemeContext } from './theme-provider';
import * as normalize from '../styles/normalize.css';
import * as global from '../styles/global.css';
import FadeTransition from './fade-transition'
import SEO from './seo';
import Header from './header';
import Footer from './footer';

export default function Layout({ children, location }) {
  const theme = useContext(ThemeContext);

  return (
    <div
      css={css`
        margin: 0 auto;
      `}
      onWheel={(e) => {
        theme.setShowMenu((e.nativeEvent.wheelDelta > 0 && e.nativeEvent.view.scrollY > e.nativeEvent.view.window.innerHeight))

        if (window) {
          if (window.scrollY > window.innerHeight) {
            theme.setPostIntro(true);
          } else {
            theme.setPostIntro(false);
          }
        }
      }}
      onTouchMove={(e) => {
        setTimeout(() => {
          if (window) {
            if (window.scrollY > window.innerHeight) {
              theme.setPostIntro(true);
            } else {
              theme.setPostIntro(false);
            }
          }
        }, 2000);
      }}
    >
      <SEO />
      <Global
        styles={css`
          ${normalize}
          ${global}

          html, footer {
            background-color: ${(theme.backgroundColor) ? `${theme.backgroundColor} !important` : 'inherit'};
            transition: background-color 0.6s var(--curve);
          }
        `}
      />
      <Header />
      <FadeTransition location={location}>
        <main>
          {children}
        </main>
        <Footer />
      </FadeTransition>
    </div>
  )
}
