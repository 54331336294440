import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import { ThemeProvider } from './src/components/theme-provider';

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider>
      <ParallaxProvider> 
        {element}
      </ParallaxProvider>
    </ThemeProvider>
  );
}

export const shouldUpdateScroll = (e) => {
  if (e.prevRouterProps?.location.pathname !== window.location.pathname) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  return false;
}
