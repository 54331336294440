import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import { css } from '@emotion/react';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import Image from 'gatsby-plugin-sanity-image';
import ImageButton from './image-button';
import SmallVideo from './small-video';
import ArrowUpRight from '../images/svg/arrow-up-right.svg';

export default class PortableText extends React.Component {
  constructor(props) {
    super(props);

    function getImage(id, width) {
      return getGatsbyImageData(id, { width: width }, {
        projectId: process.env.SANITY_PROJECT_ID,
        dataset: process.env.SANITY_PROJECT_DATASET,
      })?.images.fallback.src;
    }

    this.serializers = {
      types: {
        imageButton: ({ node }) => {
          return (
          <ImageButton
            css={css`
              width: 100%;
              margin-bottom: 3rem;

              img {
                width: 100%;
              }
            `}
            external={true}
            url={node.url}
            imageUrl={getImage(node.image.asset._ref, 990)}
            text={node.title}
            arrow={<ArrowUpRight />}
          />)
        }
        ,
        image: ({ node }) => (
          <img
            css={css`
              width: 100%;
              border: 1px solid var(--black);
              margin-bottom: 3rem;
            `}
            src={getImage(node.asset._ref, 990)}
          />
        ),
        simpleVideo: ({ node }) => (
          <div
            css={css`
              margin-bottom: 3rem;
            `}
          >
            <SmallVideo video={node.video} />
          </div>
        ),
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.blocks !== nextProps.blocks) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    return (
      <BlockContent
        className={this.props.className}
        css={css`
          ul {
            padding: 0;
            margin: 2rem 0;

            li {
              list-style: none;

              &::before {
                content: '– ';
              }
            }
          }
        `}
        blocks={this.props.blocks}
        serializers={(this.props.serializers) ? this.props.serializers : this.serializers}
        projectId={process.env.SANITY_PROJECT_ID}
        dataset={process.env.SANITY_PROJECT_DATASET}
        renderContainerOnSingleChild
      />
    )
  }
}
